.input-group {
    // improve captcha layout
    .captcha {
        position: absolute;
        right: 0;
        bottom: 0;
        border: none;
        padding: 0.13rem;

        ~ .form-control {
            padding-right: 100px;
        }
    }

    &.is-invalid {
        .invalid-feedback {
            display: block;
        }
    }
    
    .invalid-feedback {
        color: $danger;
    }

    &.input-group-outline {
        .form-control {
            width: 100%;
        }
    }

    &.input-group-outline,
    &.input-group-dynamic {
        .form-label ~ .form-control {
            &::placeholder {
                opacity: 0;
            }
        }

        &.is-focused,
        &.is-filled {
            .form-label ~ .form-control {
                &::placeholder {
                    opacity: 1;
                }
            }
        }
    }
}
